import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import Banner from '../components/Banner';
import Layout from '../components/layout';
import Map from '../components/Map';

const Contact = () => {
  return (
    <Layout title="Contact Us">
      <Banner title="Contact Us" />
      <Container>
        <Row>
          <Col sm={12} md={6}>
            <div className="page-content">
              <h1>Head Office</h1>
              Knole Road, Dartford, Kent, DA1 3JN
              <p>
                <FaPhone /> <a href="tel:07494 160 670">07494 160 670</a>
              </p>
              <p>
                <FaEnvelope />{' '}
                <a href="mailto:info@summitwealth.co.uk">
                  info@summitwealth.co.uk
                </a>
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="page-content contact-page-form">
              <Form
                goform=""
                action="https://goform.app/s/48gQInlnDGtr7473zrUE4aqs67gX9MhO"
                method="post"
                noValidate
              >
                <FormGroup>
                  <Label for="name">
                    Your Full Name <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Full Name"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    Your Email Address <span>*</span>
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your Email Address"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="phone">
                    Your Phone Number <span>*</span>
                  </Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Your Phone Number"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="message">
                    How can we help? <span>*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Tell us how we can help you"
                    rows={5}
                  />
                </FormGroup>
                <Button color="danger" type="submit">
                  Submit
                </Button>

                <p className="form-note">
                  <span>* </span>
                  By submitting details on this form you’re agreeing to Summit
                  Wealth Ltd and it's business partners contacting you to
                  discuss the areas you've expressed an interest in.
                </p>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
            <div className="page-content">
              The guidance and/or advice contained within the website is subject
              to the UK regulatory regime and is therefore targeted at consumers
              based in the UK.
            </div>
          </Col>
        </Row>
      </Container>
      <Map />
    </Layout>
  );
};

export default Contact;